<template>
  <div>
    <b-card-code title="معلومات الفرد">
      <validation-observer ref="simple">
    
          <b-row>
            <b-col md="" xl="2">
             <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="Inputperson"
                  :state="errors.length > 0 ? false : null"
                >
                <label>الشخص المدخل</label>
                <v-select
               
                  v-model="selectedRel"
                  :reduce="(val) => val.value"
                  :options="optionRelationship"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="12" xl="2">
              <b-form-group label-for="name">
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <label>الأسم</label>

                  <b-form-input
                    id="helperInput"
                    :state="errors.length > 0 ? false : null"
                    placeholder="الاسم"
                    v-model="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" xl="2">
              <b-form-group label-for="fname">
                <validation-provider #default="{ errors }" name="fname" rules="required">
                  <label>أسم الأب</label>
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    id="helperInput"
                    v-model="FName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" xl="2">
              <b-form-group label-for="lname">
                <validation-provider #default="{ errors }" name="lname" rules="required">
                  <label>النسبة </label>

                  <b-form-input
                    id="helperInput"
                    :state="errors.length > 0 ? false : null"
                    v-model="FamilyName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <div > -->
            <b-col md="12" xl="2" v-if="this.selectedRel.id == 5">
              <b-form-group label-for="mname">
                <validation-provider #default="{ errors }" name="mname" rules="required">
                  <label>أسم الأم</label>

                  <v-select v-model="Mname" :options="optionMom" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" xl="2" v-else>
              <b-form-group label-for="mname">
                <validation-provider #default="{ errors }" name="mname" rules="required">
                  <label>أسم الأم</label>

                  <b-form-input
                    id="helperInput"
                    :state="errors.length > 0 ? false : null"
                    v-model="Mname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" xl="2">
              <b-form-group label-for="bplace">
                <validation-provider #default="{ errors }" name="field" rules="required">
                  <label> مكان الولادة</label>
                  <b-form-input
                    id="helperInput"
                    :state="errors.length > 0 ? false : null"
                    v-model="PlaceBir"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- </div> -->

            <!-- phone number -->
            <b-col md="12" xl="4">
                     <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="BirthDate"
                  :state="errors.length > 0 ? false : null"
                >
                <label> تاريخ الميلاد</label>

                <flat-pickr
                  v-model="dateOfBirth"
                  class="form-control"
                  placeholder="حدد تاريخ الميلاد"
                  :config="{ maxDate: max }"
                />
              </b-form-group>
               <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <!-- date -->

            <!-- time -->
            <b-col md="6" xl="2">
              <validation-provider #default="{ errors }" name="select" rules="required">
                <b-form-group label-for="time" :state="errors.length > 0 ? false : null">
                  <label> الجنس</label>
                  <v-select
                    v-model="selectgender"
                    :reduce="(val) => val.value"
                    :options="optiongender"
                    @input="getWorkType(selectgender)"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <!-- Numeral Formatting -->
            <b-col md="6" xl="2">
              <validation-provider
                #default="{ errors }"
                name="Nationality"
                rules="required"
              >
                <b-form-group label-for="time" :state="errors.length > 0 ? false : null">
                  <label> الجنسية</label>
                  <v-select
                    v-model="selectedNa"
                    :reduce="(val) => val.value"
                    :options="optionNationality"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="2">
              <validation-provider
                #default="{ errors }"
                name="social status"
                rules="required"
              >
                <b-form-group label-for="time" :state="errors.length > 0 ? false : null">
                  <label> الحالة الأجتماعية</label>
                  <v-select
                    v-model="selectstatus"
                    :reduce="(val) => val.value"
                    :options="MartialState"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col md="6" xl="2">
              <validation-provider
                #default="{ errors }"
                name="studylevel"
                rules="required"
              >
                <b-form-group label-for="" :state="errors.length > 0 ? false : null">
                  <label> التحصيل العلمي</label>
                  <v-select
                    v-model="selectedStudy"
                    :reduce="(val) => val.value"
                    :options="optionStudy"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="2">
              <validation-provider
                #default="{ errors }"
                name="studystate"
                rules="required"
              >
                <b-form-group label-for="" :state="errors.length > 0 ? false : null">
                  <label> حالة التعليم</label>
                  <v-select
                    v-model="selectedStudyState"
                    :reduce="(val) => val.value"
                    :options="EducationalState"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="2">
              <validation-provider #default="{ errors }" name="NewWork" rules="required">
                <b-form-group label-for="" :state="errors.length > 0 ? false : null">
                  <label> العمل الحالي</label>
                  <v-select
                    v-model="selectedWork"
                    :reduce="(val) => val.value"
                    :options="optionJobs"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="12" xl="2">
              <b-form-group label-for="delimiter">
                <label>الملاحظات</label>
                <b-form-textarea
                  id="textarea-default"
                  v-model="nowNote"
                  placeholder="الملاحظات"
                  rows="1"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="2">
              <validation-provider #default="{ errors }" name="LastWork" rules="required">
                <b-form-group label-for="" :state="errors.length > 0 ? false : null">
                  <label> العمل السابق</label>
                  <v-select
                    v-model="selectedLastWork"
                    :reduce="(val) => val.value"
                    :options="optionJobs"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="12" xl="2">
              <b-form-group label-for="delimiter">
                <label>الملاحظات</label>
                <b-form-textarea
                  id="textarea-default"
                  v-model="lastNote"
                  placeholder="الملاحظات"
                  rows="1"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="2">
              <validation-provider
                #default="{ errors }"
                name="person state"
                rules="required"
              >
                <b-form-group label-for="" :state="errors.length > 0 ? false : null">
                  <label> حالة الفرد</label>
                  <v-select
                    v-model="selectedS"
                    :reduce="(val) => val.value"
                    :options="RegistrationStatuses"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="4" xl="4">
              <b-form-group label-for="number">
                <!-- <validation-provider #default="{ errors }" name="note" rules="required"> -->
                  <label> الملاحظات</label>
                  <b-form-textarea
                    v-model="singleNotes"
                    id="textarea-default"
                  
                    placeholder="الملاحظات"
                    rows="1"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
            </b-col>
             <b-col class="border-Primary" md="12" xl="4" v-if="AddedPerson">
              <b-button variant="edit" @click="editbeneficiary">
                <span class="align-middle"> تعديل معلومات الفرد الشخصية</span>
              </b-button>
            </b-col>
          </b-row>
          <br />
          <br />   
            
          <b-modal
            ref="my-modal"
            centered
            ok-title="حفظ"
            cancel-variant="outline-secondary"
            title-tag="div"
          >           <!-- <b-card-code  title="معلومات الاتصال الخاصة بالعائلة" >/ -->
            <validation-observer ref="phonesimple">
              <b-card-code>
                <b-form v-for="(phone, i) in phones" :key="i">
                
                  <validation-provider
                    #default="{ errors }"
                    name="phoneType"
                    rules="required"
                  >
                  <b-form-group label-for="phoneType"  :state="errors.length > 0 ? false : null">
                    <label> وسبلة الاتصال</label>
                    <v-select
                      v-model="phone.contact_type"
                      :options="contactType"
                      label="name"
                    />
                  </b-form-group>
      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="titlekn"
                    rules="max:14|regex:^([0-9]+)$|required"
                  >
                    <b-form-group
                      label-for="cardNumber"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> الرقم</label>
                      <b-form-input
                        v-model="phone.number"
                        id="cardNumber"
                        class="form-control"
                        :raw="false"
                        placeholder=" 999 999 099"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>

                  <b-form-group label-for="docNote">
                    <label>الملاحظات</label>
                    <b-form-textarea
                      id="textarea-default"
                      v-model="phone.notes"
                      placeholder="الملاحظات"
                      rows="1"
                    />
                  </b-form-group>
                  <hr />
                </b-form>
              </b-card-code>
              <b-row>
                <b-col xl="4"></b-col>
                <b-col xl="4">
                  <b-button variant="outline-primary" @click="addNumberr">
                    <span class="align-middle"> إضافة الرقم </span>
                  </b-button>
                </b-col>
              </b-row>
              <hr />
              <b-table responsive="sm" :items="itemsNum" :fields="fieldsNum" />
            </validation-observer>
            <!-- </b-card-code> -->

            <template #modal-footer>
              <b-button variant="purple" @click="addNumbertoPerson">
                <span class="align-middle"> حفظ </span>
              </b-button>
            </template>
          </b-modal>

        

          <b-modal
            ref="modal-documents"
            centered
            cancel-variant="outline-secondary"
            title-tag="div"
          >
            <validation-observer ref="documentsimple">
              <b-card-code>
                <b-form v-for="(document, index) in documents" :key="index">
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-group
                      label-for="cardNumber"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> نوع الوثيقة</label>
                      <v-select
                        v-model="document.document_type"
                        :options="optionDocuments"
                        label="name"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    rules="regex:^([0-9]+)$|required"
                  >
                    <b-form-group
                      label-for="numberdocument"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> رقم الوثيقة</label>

                      <b-form-input v-model="document.number" placeholder="رقم الوثيقة" />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>

                  <validation-provider #default="{ errors }" name="daisp" rules="required">
                    <b-form-group
                      label-for="date"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> تاريخ الأصدار</label>
                      <flat-pickr
                        v-model="document.release_date"
                        class="form-control"
                        placeholder="حدد تاريخ الأصدار"
                        locale="en"
                          :config="{      static:'true' }"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>

                  <validation-provider #default="{ errors }" name="dateDisp" rules="">
                    <b-form-group
                      label-for="date"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> تاريخ الأنتهاء</label>
                      <flat-pickr
                        v-model="document.expiration_date"
                        class="form-control"
                        placeholder="حدد تاريخ الأنتهاء"
                          :config="{      static:'true' }"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>

                  <b-form-group label-for="docNote">
                    <label>الملاحظات</label>
                    <b-form-textarea
                      id="textarea-default"
                      v-model="document.notes"
                      placeholder="الملاحظات"
                      rows="1"
                    />
                  </b-form-group>
                  <hr />
                </b-form>
              </b-card-code>
              <b-row>
                <b-col xl="4"></b-col>
                <b-col xl="4">
                  <b-button variant="outline-primary" @click="adddocument">
                    <span class="align-middle"> إضافة الوثيقة </span>
                  </b-button>
                </b-col>
              </b-row>
              <hr />
              <b-table responsive="sm" :items="itemsDoc" :fields="fieldsDoc" />
            </validation-observer>
            <template #modal-footer>
              <b-button variant="purple" @click="addDocumenttoPer">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> حفظ </span>
              </b-button>
            </template>
          </b-modal>

          

          <b-modal
            ref="modal-weakness"
            centered
            cancel-variant="outline-secondary"
            title-tag="div"
          >
            <validation-observer ref="personweakness">
              <b-card-code>
                <b-form>
                  <b-row v-for="(weak, index) in weaknesses" :key="index">
                    <!-- block -->
                    <b-col md="4" xl="6">
                      <validation-provider #default="{ errors }" rules="required">
                        <b-form-group label-for="weakness">
                          <label> نقاط الضعف</label>
                          <treeselect
                            :multiple="false"
                            :options="optionsWeak"
                            v-model="weak.weakness_id"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>

                    <!-- delimiters -->
                    <b-col md="8" xl="6">
                      <b-form-group label-for="delimiter">
                        <label> الملاحظات</label>
                        <b-form-textarea
                          id="textarea-default"
                          placeholder="الملاحظات"
                          v-model="weak.notes"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <br />
                  <br />
                  <b-row> </b-row>
                </b-form>
              </b-card-code>
              <b-row>
                <b-col md="6" xl="4"></b-col>
                <b-col md="6" xl="4">
                  <b-button variant="outline-primary" @click="addWeak">
                    <span class="align-middle"> إضافة النقطة </span>
                  </b-button>
                </b-col>
              </b-row>
              <hr />
              <b-table responsive="sm" :items="itemsWek" :fields="fieldsWek" />
            </validation-observer>
            <template #modal-footer>
              <b-button variant="purple" @click="addWeaknesstoPer">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> حفظ </span>
              </b-button>
            </template>
          </b-modal>
       
          <b-modal
            ref="modal-services"
            centered
            cancel-variant="outline-secondary"
            title-tag="div"
          >
            <validation-observer ref="Personservices">
              <b-card-code>
                <b-form v-for="(serv, index) in services" :key="index">
                 
                    <!-- block -->
                 
            

                      <validation-provider
                        #default="{ errors }"
                        
                        rules="required"
                      >
                        <b-form-group
                          label-for="servicename"
                       
                        >
                          <label> اسم الخدمة </label>
                          <v-select
                            v-model="serv.MainService"
                            :options="optionService"
                            @input="getSubServices(serv.MainService.value)"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    
                 
                      <validation-provider
                        #default="{ errors }"
                       
                        rules="required"
                      >
                        <b-form-group
                          label-for="cardNumber"
                          
                        >
                          <label> الخدمة الفرعية </label>
                          <v-select
                            v-model="serv.proposal_service_id"
                          
                            :options="optionSubServices"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>

                  
                      <b-form-group label-for="delimiter">
                        <label>الملاحظات</label>
                        <b-form-input
                          id="textarea-default"
                          v-model="serv.notes"
                          placeholder="الملاحظات"
                          rows="1"
                        />
                      </b-form-group>
     

                  <br />
                 
             
                </b-form>
              </b-card-code>
              <b-row>
                <b-col xl="4"></b-col>
                <b-col xl="4">
                  <b-button variant="outline-primary" @click="addServ">
                    <span class="align-middle"> إضافة الخدمة </span>
                  </b-button>
                </b-col>
              </b-row>
              <hr />
              <b-table responsive="sm" :items="itemsServ" :fields="fieldsServ" />
            </validation-observer>
            <template #modal-footer>
              <b-button variant="purple" @click="addServicestoPer">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> حفظ </span>
              </b-button>
            </template>
          </b-modal>
          <br />
           <br />
           <b-row>
            <b-col md="6" xl="3">
            <b-button variant="purple" @click="addServPerson">
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">إضافة خدمة للفرد </span>
            </b-button>
            <br />
            <br />
            <div></div>

            <feather-icon class="text-success" icon="CheckIcon" v-if="saveServices" />
          </b-col>
          <br />
           <br />
         
           
          <!-- <b-row v-for="(service, index) in services" :key="index">
          <br />
          <br />
           {{index}}

            <b-col md="6" xl="3">
              <validation-provider
                #default="{ errors }"
                name="service"
                rules=""
              >
                <b-form-group
                  label-for="service"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> الخدمة</label>
                  <v-select
                 
                    v-model="service.Mainservice"
                    :reduce="(val) => val.value"
                    :options="optionService"
                    @input="getSubServices(service, index)"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3">
              <validation-provider
                #default="{ errors }"
                name="documentType"
                rules=""
              >
                <b-form-group
                  label-for="subService"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> الخدمة الفرعية </label>
                 
                  
                  <v-select
                    v-model="service.proposal_service_id"
                    :reduce="(val) => val.value"
                    :options="optionSubServices[index]"
                    
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col md="12" xl="4">
              <b-form-group label-for="delimiter">
                <validation-provider
                  #default="{ errors }"
                  name="Ndocument"
                  rules=""
                >
                  <label>الملاحظات</label>
                  <b-form-textarea
                    id="textarea-default"
                    :state="errors.length > 0 ? false : null"
                    v-model="service.notes"
                    placeholder="الملاحظات"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            
            <b-row>
              <b-col md="2" xl="3" v-if="index != 0">
                <b-button variant="gr" @click="removeservice(index)">
                  <span class="align-middle"> إلغاء </span>
                </b-button>
              </b-col>
            </b-row>
            <br />
              <br />
            
            
          </b-row> -->
    
          <!-- <b-row>
            <b-col md="1" xl="3">
              <b-button variant="gr" @click="anotherservice">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">إضافة خدمة آخرى </span>
              </b-button>
            </b-col>
          </b-row>
          <br />
           <br /> -->
          
           <b-col md="6" xl="3">
            <b-button variant="purple" @click="addWekPerson">
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">إضافة نقطة ضعف للفرد </span>
            </b-button>
            <br />
            <br />
            <div></div>

            <feather-icon class="text-success" icon="CheckIcon" v-if="saveWeakness" />
          </b-col>


            <b-col md="6" xl="3">
            <b-button variant="purple" @click="addDocPerson">
              <feather-icon icon="PlusIcon" class="mr-50" />

              <span class="align-middle">إضافة وثيقة </span>
            </b-button>
            <br />
            <br />
            <h6 class="text-success" v-if="saveDocuments">
              <feather-icon class="text-success" icon="CheckIcon" />
            </h6>
          </b-col>
               <b-col md="6" xl="3">
            <b-button variant="purple" @click="addNumPerson">
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">إضافة وسيلة اتصال </span>
            </b-button>
            <br />
            <br />
            <h6 class="text-success" v-if="saveNumbers">
              <feather-icon class="text-success" icon="CheckIcon" />
            </h6>
          </b-col> </b-row>
           <br /> <br />
             <b-row>
        <b-col md="12" xl="4"></b-col>
        <b-col
          class="border-Primary d-flex justify-content-between flex-column mt-1 mt-sm-0"
          md="12"
          xl="3"
        >
          <b-button
          
            variant="outline-primary"
            class="shadow"
        @click="addbeneficiary"
          >
            <feather-icon icon="UserPlusIcon" class="mr-50" />
            <span class="align-middle">إضافة الفرد</span>
          </b-button>
          <br />
            <br />
        </b-col>
      </b-row>
          <br />
    
      </validation-observer>
    </b-card-code>
  </div>
</template>

<script>
// import FormTextAreaDefault from '..\form-textarea\FormTextArea.vue'
import vSelect from "vue-select";
// import person from './person.vue';

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormFile,
  BFormGroup,
  BForm,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  BFormInvalidFeedback,
  BTable
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
// import '@core/scss/vue/libs/vue-flatpicker.scss';
import "flatpickr/dist/flatpickr.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "cleave.js/dist/addons/cleave-phone.us";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, max, is_earlier } from "@validations";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapState } from "vuex";
import { BFormDatepicker, BFormInput, BFormTextarea, BButton } from "bootstrap-vue";
// import {
//   BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
import  {personDetails} from '@/@core/mixins/personDetails.js'
// } from 'bootstrap-vue'
export default {
  name: "newBen",
  mixins:[personDetails],
  components: {
    Treeselect,
    is_earlier,
    BCardCode,
    required,
    BFormInvalidFeedback,
    BButton,
    max,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    flatPickr,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    ToastificationContent,
    BRow,
BTable,
    BCol,
  },

  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth());


    return {
      jobs: [],
optionSubServices:[],
optionSubServices1:[],
      RegistrationStatuses: [],
      notesFamily: "",
      optionJobs: [],
      terms: false,
      terms2: true,
          saveWeakness: false,
          saveServices:false,
      saveDocuments: false,
      saveNumbers: false,
      selectstatus: "",
      dateOfBirth: "",
      singleNotes: "",
      file: [],
      name: "",
      lName: "",
      father: "",
      FamilyName: "",
      mother: "",
      Mname: "",
      FName: "",
      lastName: "",
      PlaceBir: "",
      numberFamily: 0,
      numberChild: 0,
      dateReg: "",
      dateDisp: "",
      selectedS: "",
      selectedMom: "",
      optionMom: [],
      selectedWeak: [],
      selectedStudyState: "",
      selectedLastWork: "",
      nowNote: "",
      note: "",
      lastNote: "",
      lastWork: "",
      AddedPerson:false,
      selectedStudy: "",
      selectedWork: "",
      selectedRel: "",
      selectedNa: "",
      selectgender: "",
      selectedFamily: "",
      selectedknow: "",
      termW: false,
      number: "",
      numberphone: "",
      selectedCall: "",
      optionService:[],
          Personphones: [],
      Personweakness: [],
      Personservices:[],
        PersonDocuments: [],
         alldocuments: [],
      value: null,
      documents: [
        {
          release_date: "",
          expiration_date: "",
          number: null,
          document_type_id: null,
          notes: "",
        },
      ],
      phones: [
        {
          number: "",
          notes: "",
          contact_type: "",
        },
      ],
      
       services: [
        {
         
          proposal_service_id: "",
          notes: "",
        },
      ],
      dateRelease: "",
      dateEnd: "",
      del: false,
      rules: {
        required,
        isBeyond: {
          compare: this.dateRelease,
          dateType: "تاريخ الانتهاء",
        },
      },
      number: "",
      value: "",

      optionTo: [],
      optionDocuments: [],
      optionsWeak: [],
      contactType: [],

      optionWeakness: [],
      EducationalState: [],

      optiongender: [],
      // optionState: [ "زائر",  "مسجل" ],
      optionNationality: [],
      optionStudy: [],
      optionRelationship: [],
      MartialState: [],

      max: maxDate,
      msg: {
        required: "هذا الحقل مطلوب",
      },
      term: false,
      edit: false,
      delete: false,
      added: false,
      add: true,
      personid: "",
      id: "",
      weaknotes: "",

      userId: "",
    };
  },

  computed: {
    isDisabled: function () {
      return !this.term;
    },
    isEdit: function () {
      return !this.edit;
    },

    isAdd: function () {
      return !this.add;
    },
    isDelete: function () {
      return !this.delete;
    },
    isAdded: function () {
      return !this.added;
    },

    ...mapState({
      title: (state) => state.motherName.title,
    }),
  },

  created() {
    this.getAlldrowpDown();
   this.getServiceType();
    this.getWeak();
    this.rules = {
      is_earlier: {
        compare: this.documents.expireDate,
        dateType: "تاريخ التسجيل",
      },
      // required,
    };
    this.userId = localStorage.getItem("userId");
  },

  methods: {
   
    addbeneficiary() {
      //console.log(this.selectedWeak,);
      return new Promise((resolve, reject) => {
        this.$refs.simple.validate().then((success) => {
          if (success) {
            this.id = this.$store.state.id;
            let data;
//             let Personservices=[]

// this.services.forEach(el=>{
//   Personservices.push({proposal_service_id:el.proposal_service_id,notes:el.notes})
// })
              data = {
                first_name: this.name,
                last_name: this.FamilyName,
                father_name: this.FName,
                mother_name: this.Mname,
                birth_date: this.dateOfBirth,
                birth_place: this.PlaceBir,
                nationality_id: this.selectedNa,
                martial_status_id: this.selectstatus,
                family_relationship_id: this.selectedRel,
                educational_attainment_id: this.selectedStudy,
                educational_status: this.selectedStudyState,
                current_work_id: this.selectedWork,
                current_work_note: this.nowNote,
                last_work_note: this.lastNote,
                last_work_id: this.selectedLastWork,
                registration_status: this.selectedS,
                note: this.singleNotes,
                gender: this.selectgender,
                
                family_id: this.$route.params.id,
                person_services:this.Personservices,
              
                   contact_details: this.Personphones,
                person_documents: this.PersonDocuments,
                weaknesses: this.Personweakness,

                // weaknotes: "",
                // addedBy: this.userId,
              };
            
              function replaceUndefinedOrNull(key, value) {
                if (value === null || value === "" || value === undefined) {
                  return undefined;
                }

                return value;
              }

              let datajson = JSON.stringify(data, replaceUndefinedOrNull);
              let dataparse = JSON.parse(datajson);
              function isEmptyObject(obj) {
                return JSON.stringify(obj) === "[{}]";
              }
              for (let key of Object.keys(dataparse)) {
                if (isEmptyObject(dataparse[key])) {
                  delete dataparse[key];
                }
              }

            this.$http.post("/api/v1/persons", dataparse).then((res) => {
              //console.log(res);
            //   this.personid = res.data.data.id;
            //   this.documents=res.data.data.documents
            //     this.phones=res.data.data.contact_details
                // this.AddedPerson=true
        
            //   this.edit = true;
            //   this.delete = true;
              this.added = true;
              this.add = false;
                this.$swal({
              

                  title: "تم إضافة الفرد",
                  icon: "success",
                  confirmButtonText: "موافق",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
               (this.documents = [
                  {
                    release_date: "",
                    expiration_date: "",
                    number: null,
                    document_type_id: null,
                    notes: "",
                  },
                ]),
                  (this.phones = [
                    {
                      number: "",
                      notes: "",
                      contact_type: "",
                    },
                  ]),
                   this.name= "",
               this.FamilyName= "",
                 this.FName= "",
                this.Mname= "",
           this.dateOfBirth= "",
                 this.PlaceBir= "",
               this.selectedNa= "",
                this.selectstatus= "",
              this.selectedRel= "",
                 this.selectedStudy= "",
              this.selectedStudyState= "",
                this.selectedWork= "",
              this.nowNote= "",
              this.lastNote= "",
                this.selectedLastWork= "",
                 this.selectedS= "",
               this.singleNotes= "",
              this.selectgender= "",
                this.weaknesses = [
                  {
                    weakness_id: "",
                    notes: "",
                  },
                ];
                requestAnimationFrame(() => {
                  this.$refs.simple.reset();
                });
           
           
            });
          }
        }).catch(error => {
   
              this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.message}`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );
         
        
      })
      });
      //  this.$refs['simple'].reset()
    },
    editbeneficiary() {

      let data = {
          first_name: this.name,
                last_name: this.FamilyName,
                father_name: this.FName,
                mother_name: this.Mname,
                birth_date: this.dateOfBirth,
                birth_place: this.PlaceBir,
                nationality_id: this.selectedNa,
                martial_status_id: this.selectstatus,
                family_relationship_id: this.selectedRel,
                educational_attainment_id: this.selectedStudy,
                educational_status: this.selectedStudyState,
                current_work_id: this.selectedWork,
                current_work_note: this.nowNote,
                last_work_note: this.lastNote,
                last_work_id: this.selectedLastWork,
                registration_status: this.selectedS,
                note: this.singleNotes,
                gender: this.selectgender,
         
              
      };

      //console.log(data);
      let url = `/api/v1/persons/${this.personid}`;
      this.$http.put(url, data).then((res) => {
        //console.log(res);

       this.$toast({
          component: ToastificationContent,
          props: {
            title: "تم طلب التعديل بانتظار موافقة مدير المعلومات",
            icon: "CheckIcon",
              variant: "success",
            },
          },
          {
            position: "top-center",
          });
          
           })
    },
     anotherservice() {
//console.log('anotherservice')
     this.services.push({
    Mainservice:"",
       proposal_service_id: "",
       notes: "",
     });

    },
    removeservice(index) {
      this.services.splice(index, 1);
    },
getServiceType() {
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
        let proposal_services = res.data.data.proposal_services;
        proposal_services.forEach((el) => {
          this.optionService.push({ label: el.name, value: el.id });
        });
      });
    },
    deletebeneficiary() {
      this.$swal({
        title: "هل انت متأكد من الحذف",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "نعم",
        cancelButtonText: "لا",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: "warning",
            title: "ستقوم بحذف جميع البيانات الخاصة بالفرد",
            confirmButtonText: "موافق",
            cancelButtonText: "الغاء",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "لا",
            },
          }).then((result) => {
            if (result.value) {
              this.$http.delete("/api/deletebinifit/" + this.personid).then((res) => {
                //console.log(res);
                this.$emit("func", "");
              });
            }
          });
        }
        this.$emit("func", "");
      });
      // (this.name = ""),
      //   (this.FamilyName = ""),
    },

    onClick() {
      this.newBens.push(newBen);
    },
   
    updateWeakness(){ let data = {
        person_id: this.personid,
        weaknesses: this.selectedWeak,
      };

      //console.log(this.selectedWeak);
      this.$http.put("/api/v1/persons/person_weaknesses", data).then((res) => {
        //console.log(res);
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "تم طلب التعديل بانتظار موافقة مدير المعلومات",
              icon: "CheckIcon",
              variant: "success",
            },
          },
          {
            position: "top-center",
          }
        );
      });
    },
    UpdateNumber(id){
//console.log(id)
  let data = null;
      //console.log("f", this.phones);
      this.phones.forEach((el) => {
        data = {
          number: el.number,
          note: el.notes,
          contact_type: el.contact_type,
        };
      });
      //console.log(data);
      this.$http.put("/api/v1/contact_details/" + id, data).then((res) => {
        //console.log(res);
         this.$toast({
          component: ToastificationContent,
          props: {
            title: "تم طلب التعديل بانتظار موافقة مدير المعلومات",
            icon: "CheckIcon",
              variant: "success",
            },
          },
          {
            position: "top-center",
          });
      
      });
    
    },
   
     Noweak() {
      this.term = !this.term;
    },
getWorkType(id){
  //console.log(id)
   this.optionJobs=[]
  this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
     let work_types = res.data.data.work_types;
        work_types.forEach((el) => {
         
          if(el.gender == id || el.gender == 0){
          this.optionJobs.push({ label: el.name, value: el.id });
         } });
            //console.log( this.optionJobs)
      ;})
},
getSubServices(id) {
      this.optionSubServices = [];
      //console.log(id);
      let url = `/api/v1/main_services/${id}/proposal_services`;
      this.$http.get(url).then((res) => {
        //console.log(res);
        let subServices = res.data.data;
        subServices.forEach((el) => {
          this.optionSubServices.push({ label: el.name, value: el.id });
        });
      });
    },
    getWeak() {
      this.optionsWeak = [];
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
        let subweak = res.data.data.sub_weaknesses;
        let Weak = res.data.data.weaknesses;

        Weak.forEach((el) => {
          let obj = {};
          obj.children = [];
          obj.label = el.name;
          obj.id = el.id;
          //console.log("j", obj);
          subweak.forEach((ele, index) => {
            if (el.id == ele.parent_id) {
              obj.children.push({ label: subweak[index].name, id: ele.id });
              //console.log("SS", obj.children);
            }
          });
          this.optionsWeak.push(obj);

          //console.log(obj);
        });
      });
    },

   
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.btn-purple {
  color: white;
  background-color: #0971b8 !important;
  width: 200px;
}
.btn-edit {
  color: white;
  width: 200px;
  background-color: #28c76f !important;
}
.btn-delete {
  color: white;
  width: 200px;
  background-color: #ea5455 !important;
}
.label{
  font-size:15px
}

.but {
  top: 25px;
}
</style>